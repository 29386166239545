<template>
  <div class="approved-partner--wrapper">
    <LadTextCover v-bind="cover">
      <LadContainerMediaText>
        <template slot="title"><span></span></template>

        <template slot="image" />

        <template slot="description">
          <img
            src="@/assets/images/approved-partner/touristio_by_localadventures.png"
            class="sprite-icons"
          />
        </template>
      </LadContainerMediaText>
    </LadTextCover>

    <el-container class="approved-partner--top">
      <el-main>
        <h1 class="approved-partner--subtitle">{{ $t(`${base}.subtitle`) }}</h1>

        <p v-html="$t(`${base}.description`)"></p>

        <el-button
          type="success"
          plain
          icon="el-icon-info"
          @click="handleClick"
          >{{ $t(`${base}.register_here_cta`) }}</el-button
        >
      </el-main>
    </el-container>

    <div class="wrapper-color">
      <el-container class="approved-partner--middle">
        <el-main>
          <h3 class="approved-partner--section-title">{{
            $t(`${base}.what_offer_you`)
          }}</h3>

          <LadWhatsOfferYou />
        </el-main>
      </el-container>
    </div>

    <el-container class="approved-partner--register">
      <el-main>
        <h3 class="approved-partner--section-title">{{
          $t(`${base}.thank_you_for_your_interest.title`)
        }}</h3>

        <p class="approved-partner--section-description">{{
          $t(`${base}.thank_you_for_your_interest.description`)
        }}</p>

        <!-- <h3 class="approved-partner--section-title">{{
          $t(`${base}.register_here`)
        }}</h3>

        <LadZohoFormApprovedPartner /> -->
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StaticMetaMixin from '@/utils/mixin/static-meta'

export default {
  name: 'LadApprovedPartner',
  components: {
    LadTextCover: () =>
      import(
        /* webpackChunkName: "lad-text-cover" */ '@/components/cover/text'
      ),
    LadContainerMediaText: () =>
      import(
        /* webpackChunkName: "lad-container-media-text" */ '@/components/container-media-text/index'
      ),
    // LadZohoFormApprovedPartner: () =>
    //   import(
    //     /* webpackChunkName: "lad-zoho-form--approved-partner" */ '@/components/zoho-form/approved-partner'
    //   ),
    LadWhatsOfferYou: () =>
      import(
        /* webpackChunkName: "lad-whats-offer-you" */ '@/components/pages/approved-partner/whats-offer-you'
      ),
  },
  mixins: [StaticMetaMixin],
  data() {
    return {
      base: 'approved_partner',
    }
  },
  computed: {
    ...mapGetters('settings', ['language']),
    ...mapGetters('device', ['isMobile']),
    cover() {
      return {
        src: `${process.env.DO_SPACES_CDN_ENDPOINT}/web-client/covers/couple-nice-location-2.jpg`,
        srcset: `${process.env.DO_SPACES_CDN_ENDPOINT}/web-client/covers/couple-nice-location-2.jpg 1x`,
        imgMinHeight: 600,
        imgMaxWidth: this.isMobile ? 900 : 1920,
      }
    },
    cacheKey() {
      const name = this.$route.path.replace(/\//g, '-').replace('-', '')

      return `${name}-${this.language}--${this.isMobile ? 'm' : 'd'}`
    },
  },
  methods: {
    handleClick() {
      // this.$scrollTo('.lad-zoho--approved-partner', 400, { offset: -120 })
      this.$scrollTo('.approved-partner--register', 400, { offset: -120 })
    },
  },
}
</script>

<style lang="scss">
.approved-partner--wrapper {
  background-color: white;

  .text-cover {
    .lad-container-media-text {
      position: absolute;
      z-index: 10;
      width: 100%;
      height: 100%;
      padding: 0;
      background-color: rgba(0, 0, 0, 0.25);

      &--wrapper {
        flex-direction: column;
      }

      &--wrapper,
      .el-container {
        height: 100%;
      }

      &--left {
        padding: 30px 20px 0 20px;
        span {
          display: none;
        }
      }

      &--right {
        display: none;
      }
    }

    .sprite-icons {
      width: 500px;
    }
  }

  .approved-partner--top,
  .approved-partner--register {
    .el-main {
      padding: 20px;
      text-align: center;
    }
  }

  .approved-partner--top {
    .el-button {
      margin: 20px 0;
      white-space: break-spaces;

      span {
        line-height: 1.6;
      }
    }
  }

  .wrapper-color {
    background-color: #f7f8fa;
  }

  .approved-partner--section-title {
    text-align: center;
    font-size: 1.6em;
  }

  .approved-partner--section-description {
    text-align: center;
    font-size: 18px;
    margin: 0 0 25px;
  }

  .wrapper--whats-offer-you {
    grid-gap: 30px 15px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 80px;
    padding: 0 10px;

    .sprite-icons {
      height: 150px;
    }

    .b2b--section-offer {
      text-align: center;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;

      .sprite-icons {
        height: 100px;
      }
    }
  }

  // .approved-partner--subtitle { }
}
</style>
